import React from "react"
import "./ShopHeroSection.css"
import laptop from "../../../images/website-optimized-images/laptop-mockup 3.png"
import { Button } from "react-bootstrap"
import Fade from "react-reveal"

const ShopHeroSection = () => {
  return (
    <>
      <div className="container" id="shopHeroContainer">
        <Fade bottom>
          <div className="row">
            <div className="col-lg-6" id="shopHeroTextArea">
              <h5>Retail solution</h5>
              <h1>
                The shopping center visitors <br /> solution
              </h1>
              <p>
                Welcome to u-find App, your partner to help you locate the right
                person, <br />
                equipment, meeting room or desk for a more productive work. Get
                the <br />
                location insights you need when you need it most!
              </p>
              {/* <div className="shopHero-btns">
            <Button className="shopHeroButton" variant="primary">
              View Demo
            </Button>
          </div> */}
            </div>
          </div>

          <div className="container" id="shopImageDiv">
            <img src={laptop} className="shopHeroImage" alt="ufind-laptop" />
          </div>
        </Fade>
      </div>
    </>
  )
}

export default ShopHeroSection
