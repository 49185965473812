import React from "react"
import "./RetailSolution.css"
import { IoMdCheckmarkCircle } from "react-icons/io"
import liveMap from "../../../images/website-optimized-images/live-map-3d 1.png"
import Fade from "react-reveal"

const RetailSolution = () => {
  return (
    <>
      <div className="container-fluid" id="RetailSolutionMainDiv">
        <Fade bottom>
          <div className="RetailSolutionTextArea">
            <h5>Retail solution</h5>
            <h1>The shopping center visitors solution</h1>
            <p>
              New generation shopping Spaces require a better and closer
              customers engagement. <br /> Transform visitors engagement and
              drive your business results. Build on top of existing <br />
              investments (WiFi) and augmented with existing or new sensors.{" "}
              <br />
              Our retail solution offers:
            </p>
          </div>

          <div className="container" id="RetailSolution-container">
            <div className="row" id="rowRetailSolution1">
              <div
                className="col-lg order-2 order-lg-1"
                id="RetailSolutionImageDiv"
              >
                <img
                  src={liveMap}
                  className="RetailSolutionImage"
                  alt="ufind-RetailSolutionImage"
                />
              </div>
              <div
                className="col-lg order-1 order-lg-2"
                id="rowRetailSolution2"
              >
                <p>
                  <IoMdCheckmarkCircle className="checkIconRetailSolution" />
                  Maps with wayfinding
                </p>
                <p>
                  <IoMdCheckmarkCircle className="checkIconRetailSolution" />
                  Spaces/resources analysis and reporting
                </p>
                <p>
                  <IoMdCheckmarkCircle className="checkIconRetailSolution" />
                  Notification engine integration
                </p>
                <p>
                  <IoMdCheckmarkCircle className="checkIconRetailSolution" />
                  Easy to install and use with quick setup
                </p>
                <p>
                  <IoMdCheckmarkCircle className="checkIconRetailSolution" />
                  Easy Integration or extended to other use cases
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default RetailSolution
