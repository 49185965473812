import * as React from "react"
import { useEffect } from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import RetailSolution from "../components/Shop/RetailSolution/RetailSolution"
import ShopDescription from "../components/Shop/ShopDescription/ShopDescription"
import ShopHeroSection from "../components/Shop/ShopHeroSection/ShopHeroSection"
import Pricing from "../components/Office/Pricing/Pricing"
import RequestDemo from "../components//RequestDemo/RequestDemo"
import Scroll from "../components/ScrollToTop/Scroll"

const Shop = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Layout>
        <Scroll showBelow={250} />
        <Seo title="Shop" />
        <ShopHeroSection />
        <RetailSolution />
        <ShopDescription />
        {/* <Pricing /> */}
        <RequestDemo />
        {/* <Link to="/">Go back to the homepage</Link> */}
      </Layout>
    </>
  )
}

export default Shop
