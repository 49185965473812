import React, { useState } from "react"
import "./ShopDescription.css"
import { IoIosArrowForward } from "react-icons/io"
import solution from "../../../images/website-optimized-images/solution.png"
import image2 from "../../../images/website-optimized-images/know-customers.png"
import image3 from "../../../images/website-optimized-images/stores-happy.png"
import Fade from "react-reveal"

const ShopDescription = () => {
  const [showImage1, setShowImage1] = useState(true)
  const [showImage2, setShowImage2] = useState(false)
  const [showImage3, setShowImage3] = useState(false)
  const [showArrow1, setShowArrow1] = useState(true)
  const [showArrow2, setShowArrow2] = useState(false)
  const [showArrow3, setShowArrow3] = useState(false)

  const handleClickImage1 = () => {
    setShowImage1(true)
    setShowImage2(false)
    setShowImage3(false)
    setShowArrow1(true)
    setShowArrow2(false)
    setShowArrow3(false)
  }
  const handleClickImage2 = () => {
    setShowImage2(true)
    setShowImage1(false)
    setShowImage3(false)
    setShowArrow2(true)
    setShowArrow1(false)
    setShowArrow3(false)
  }
  const handleClickImage3 = () => {
    setShowImage3(true)
    setShowImage1(false)
    setShowImage2(false)
    setShowArrow3(true)
    setShowArrow2(false)
    setShowArrow1(false)
  }

  return (
    <>
      <div className="container" id="shopDescriptionSolutionsDiv">
        <Fade bottom>
          <div className="textAreaShopDescription">
            <h5>Workplace management</h5>
            <h1>Solution description & main advantages</h1>
          </div>
          <div className="row" id="colParentShopDescription">
            <div className="col-lg-4" id="boxesParentShopDescription">
              <div
                className="box1ShopDescription"
                id="boxOneShopDescription"
                onClick={handleClickImage1}
                style={{
                  boxShadow: showImage1
                    ? "0px 5px 30px rgba(183, 193, 203, 0.4)"
                    : "none",
                }}
              >
                <h5> Visitor engagement</h5>
                <ul className="shopDescUl">
                  <li style={{ listStyleType: "none" }} className="shopDescLi">
                    Transform visitor engagement and drive <br /> business
                    results.
                  </li>
                </ul>
                <Fade right when={showArrow1} distance="110px">
                  <div className="arrowShopDescription">
                    {" "}
                    <IoIosArrowForward style={{ color: "#FFFFFF" }} />
                  </div>
                </Fade>
              </div>
              <div className="container">
                <div
                  className="box2ShopDescription"
                  onClick={handleClickImage2}
                  style={{
                    boxShadow: showImage2
                      ? "0px 5px 30px rgba(183, 193, 203, 0.4)"
                      : "none",
                  }}
                >
                  <h5> Know your customers</h5>
                  <ul style={{ listStyleType: "none" }} className="shopDescUl">
                    <li className="shopDescLi">
                      Get insights about customers, help them with wayfinding.
                    </li>
                  </ul>
                  <Fade right when={showArrow2} distance="110px">
                    <div className="arrowShopDescription2">
                      {" "}
                      <IoIosArrowForward style={{ color: "#FFFFFF" }} />
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="container">
                <div
                  className="box3ShopDescription"
                  onClick={handleClickImage3}
                  style={{
                    boxShadow: showImage3
                      ? "0px 5px 30px rgba(183, 193, 203, 0.4)"
                      : "none",
                  }}
                >
                  <h5>Keep stores happy and healthy</h5>
                  <ul style={{ listStyleType: "none" }} className="shopDescUl">
                    <li className="shopDescLi">
                      Monitor spaces and resources usage, measure <br /> the
                      environment and adjust for customer comfort.
                    </li>
                  </ul>
                  <Fade right when={showArrow3} distance="110px">
                    <div className="arrowShopDescription3">
                      {" "}
                      <IoIosArrowForward style={{ color: "#FFFFFF" }} />
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
            <div
              className="col-lg"
              id="shopimage1Div"
              style={{
                display: showImage1 ? "block" : "none",
              }}
            >
              <Fade right when={showImage1}>
                <img src={solution} alt="charts" className="shopImage1" />
              </Fade>
            </div>
            <div
              className="col-lg"
              id="shopimage2Div"
              style={{
                display: showImage2 ? "block" : "none",
              }}
            >
              <Fade right when={showImage2}>
                <img src={image2} alt="charts" className="shopImage2" />
              </Fade>
            </div>
            <div
              className="col-lg"
              id="shopimage3Div"
              style={{
                display: showImage3 ? "block" : "none",
              }}
            >
              <Fade right when={showImage3}>
                <img src={image3} alt="charts" className="shopImage3" />
              </Fade>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default ShopDescription
